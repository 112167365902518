<template>
  <div>
    <div class="header pb-6 d-flex">
      <!-- Mask -->
      <span class="mask bg-white opacity-8"></span>
      <!-- Header container -->
      <b-container fluid class="d-flex">
        <b-row>
          <b-col sm="12" md="auto" class="mt-4">
            <div
              @click="$router.back()"
              class="icon icon-shape rounded-circle shadow bg-primary text-white"
            >
              <i class="fa fa-arrow-left"></i>
            </div>
          </b-col>
          <b-col sm="12" md="auto">
            <!-- <b-row>
                            <b-col>
                                <p>
                                    37 Likes
                                    <i
                                        v-if="!liked"
                                        class="far fa-thumbs-up text-lg ml-1"
                                        @click="update_likes('like')"
                                    ></i>
                                    <i
                                        v-else
                                        class="fas fa-thumbs-up text-lg ml-1"
                                        @click="update_likes('unlike')"
                                    ></i>
                                </p>
                            </b-col>
                        </b-row> -->
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container fluid class="mt--5">
      <b-row>
        <!-- Main Col -->
        <b-col sm="12" md="8">
          <!-- Detail Description -->
          <b-row>
            <Transition>
              <b-col
                class="d-flex align-items-center justify-content-center"
                v-if="$apollo.queries.get_hackathon_idea_idea.loading"
              >
                <h1>
                  <i class="fas fa-spinner fa-spin ml-1"></i>
                </h1>
              </b-col>
              <b-col v-else>
                <b-card>
                  <b-row>
                    <b-col sm="auto"
                      ><h1 class="text-primary">
                        {{ idea.name }}
                      </h1>
                    </b-col>
                    <!-- :loading="
                                                accept_invite_button.loading
                                            "
                                            :disabled="
                                                accept_invite_button.loading
                                            "
                                            :success="
                                                accept_invite_button.success
                                            " -->
                    <b-col>
                      <base-button
                        v-if="!team.is_member && !team.requested_to_join"
                        type="primary"
                        :pill="true"
                        native-="submit"
                        class="mt-1"
                        size="sm"
                        @click="create_team_join_request()"
                        :loading="join_button.loading"
                        :success="join_button.success"
                        >Join this team
                      </base-button>
                      <badge
                        v-else-if="team.requested_to_join"
                        :rounded="true"
                        size="md"
                        type="info"
                        class="mt-1"
                      >
                        Requested to join team
                      </badge>
                    </b-col>
                    <b-col class="text-right">
                      <router-link
                        v-if="idea.is_creator"
                        :to="{
                          name: 'HackathonRudIdea',
                          params: {
                            idea_id: idea.id,
                            hackathon_id: hackathon.id,
                          },
                        }"
                      >
                        <i class="fas fa-edit text-black-50"></i>
                      </router-link>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h5>{{ idea.description }}</h5>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <div v-html="idea.detail_description"></div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </Transition>
          </b-row>
          <b-row
            v-if="idea.is_creator && idea.hackathon.ideas_require_approval"
          >
            <b-col sm="12">
              <HackathonAiIdeaFeedback />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <Transition>
                <HackathonTeamMembers
                  v-if="
                    team.member_count > 0 &&
                    !$apollo.queries.get_hackathon_idea_idea.loading
                  "
                  :team_id="team.id"
                  view_only
                ></HackathonTeamMembers>
              </Transition>
            </b-col>
          </b-row>
        </b-col>
        <!-- Side bar -->
        <b-col
          sm="12"
          md="4"
          v-if="idea.is_creator && idea.hackathon.ideas_require_approval"
        >
          <b-row>
            <b-col>
              <ApprovalSubmitter :approval_id="idea.approval.id" show_history
            /></b-col>
          </b-row>
        </b-col>
        <b-col
          sm="12"
          md="4"
          v-if="idea.is_creator && !idea.hackathon.ideas_require_approval"
        >
          <b-row>
            <b-col> <HackathonAiIdeaFeedback /></b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// Components
import Badge from "@/components/Badge.vue";
import HackathonTeamMembers from "@/views/Components/Hackathon/Team/TeamMembers.vue";
import HackathonAiIdeaFeedback from "@/views/Components/Hackathon/Idea/AiIdeaFeedback.vue";

import ApprovalSubmitter from "@/views/Components/Approval/ApprovalSubmitterComponent.vue";

// Queries
import {
  GET_HACKATHON_IDEA_IDEA,
  GET_HACKATHON_PROFILE_FROM_USER_ID,
} from "@/graphql/queries";

// Mutations
import { CREATE_HACKATHON_TEAM_JOIN_REQUEST } from "@/graphql/mutations";

// ORM
import { processIdeaRelayData, Idea } from "@/ORM/Hackathon/Idea.js";

export default {
  name: "HackathonViewIdea",
  components: {
    Badge,
    HackathonTeamMembers,
    HackathonAiIdeaFeedback,
    ApprovalSubmitter,
  },
  apollo: {
    get_hackathon_idea_idea: {
      query: GET_HACKATHON_IDEA_IDEA,
      result(data) {
        this.idea.name = data.data.hackathonIdeaIdea.name;
        this.idea.description = data.data.hackathonIdeaIdea.description;
        this.idea.detail_description =
          data.data.hackathonIdeaIdea.detailDescription;
        this.idea.is_hackathon_manager =
          data.data.hackathonIdeaIdea.isHackathonManager;
        this.idea.is_creator = data.data.hackathonIdeaIdea.isCreator;

        if (data.data.hackathonIdeaIdea.team) {
          this.team.id = data.data.hackathonIdeaIdea.team.id;
          this.team.member_count =
            data.data.hackathonIdeaIdea.team.totalMembers;
          this.team.requested_to_join =
            data.data.hackathonIdeaIdea.team.hasRequestedToJoin;
          this.team.is_member = data.data.hackathonIdeaIdea.team.isMember;
        }
        this.idea = processIdeaRelayData(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      variables() {
        return {
          idea_id: this.idea.id,
        };
      },
      update(data) {
        this.apollo_data.get_hackathon_idea_idea = data;
      },
      skip: true,
    },
    get_hakcathon_profile: {
      query: GET_HACKATHON_PROFILE_FROM_USER_ID,
      result(res) {
        let profiles = graph_utils.flatten_objects_recursive(
          graph_utils.apollo_to_obj_recursive(
            res.data.allHackathonProfileProfile
          )
        );
        if (profiles.length > 0) {
          this.hackathon_profile.id = profiles[0].id;
        }
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      variables() {
        return {
          user: store.getters.getUser.id_b64,
        };
      },
      update(data) {
        this.apollo_data.get_hakcathon_profile = data;
      },
    },
  },
  data() {
    return {
      apollo_data: {
        get_hackathon_idea_idea: null,
        get_hakcathon_profile: null,
      },
      id: null,
      idea: {
        id: null,
        name: null,
        description: null,
        detail_description: null,
      },
      hackathon_profile: {
        id: null,
      },
      team: {
        id: null,
        member_count: 0,
        is_member: false,
        requested_to_join: false,
      },
      join_button: {
        loading: false,
        success: false,
      },
      hackathon: {
        id: null,
      },
    };
  },
  methods: {
    enable_query(query) {
      if (!query.skip) {
        query.refetch();
      } else {
        query.skip = false;
        query.start();
      }
      },

    create_team_join_request() {
      this.join_button.loading = true;

      this.$apollo
        .mutate({
          mutation: CREATE_HACKATHON_TEAM_JOIN_REQUEST,
          variables: {
            team_id: this.team.id,
            profile_id: this.hackathon_profile.id,
          },
        })
        .then(() => {
          this.join_button.loading = false;

          this.join_button.success = true;
          setTimeout(() => {
            this.join_button.success = false;
          }, 1000);

          this.$notify({
            message: "Successfully requested to join team.",
            timeout: 3000,
            icon: "ni ni-check-bold",
            type: "success",
          });
          this.$apollo.queries.get_hackathon_idea_idea.refetch();
          global_event_emitter.$emit("hackathon_sidebar_reload_required");
        })
        .catch((err) => {
          console.log(err);

          if (String(err).includes("User is already in a team")) {
            this.$notify({
              message: `You are already in a team, please leave your current team first.`,
              timeout: 5000,
              icon: "ni ni-fat-remove",
              type: "warning",
            });
          } else {
            this.$notify({
              message: `Could not request to join team.`,
              timeout: 5000,
              icon: "ni ni-fat-remove",
              type: "warning",
            });
          }

          this.join_button.loading = false;
        });
    },

    get_id() {
      if (this.$route.params.idea_id) {
        this.idea.id = this.$route.params.idea_id;
        this.enable_query(this.$apollo.queries.get_hackathon_idea_idea);
      } else {
        this.idea.id = null;
      }
      if (this.$route.params.hackathon_id) {
        this.hackathon.id = this.$route.params.hackathon_id;
        this.enable_query(this.$apollo.queries.get_hackathon_idea_idea);
      } else {
        this.id = null;
      }
      // TODO: reroute to discover if no id is found.
    },
  },
  mounted() {
    this.get_id();
  },
};
</script>

<style></style>
